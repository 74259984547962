import { StarFillIcon, Typography, TypographyType } from '@vivino/js-react-common-ui';
import { CountryFlagIcon48, useI18N } from '@vivino/js-web-common';
import React from 'react';

import { UserReview as UserReviewCardType } from './reviews';
import styles from './userReviewCard.module.scss';

const TRANSLATIONS_PATH = 'components.premium_page.user_reviews';

const TRANSLATIONS = {
  byAuthorName: `${TRANSLATIONS_PATH}.by_author_name`,
};

interface UserReviewCardProps {
  review: UserReviewCardType;
  isLoading?: boolean;
}

const UserReviewCard = ({ review, isLoading }: UserReviewCardProps) => {
  const { t } = useI18N();

  if (isLoading) {
    return <div className={styles.userReviewCardSkeleton} />;
  }

  return (
    <div className={styles.userReviewCard}>
      <div className={styles.stars}>
        {[...Array.from(Array(review.numberOfStars).keys())].map((i) => (
          <StarFillIcon key={`star-${i}`} data-testid="star" />
        ))}
      </div>
      <Typography type={TypographyType.LabelLarge} className={styles.title}>
        {t(review.title)}
      </Typography>
      <Typography type={TypographyType.BodySmall} className={styles.content}>
        {t(review.content)}
      </Typography>
      <div className={styles.author}>
        <div className={styles.authorName}>
          <Typography key={review.author} type={TypographyType.LabelMedium}>
            {t(TRANSLATIONS.byAuthorName, {
              author_name: review.author,
            })}
          </Typography>
        </div>
        <div className={styles.countryFlagAndName}>
          <CountryFlagIcon48
            countryCode={review.country_code.toUpperCase()}
            className={styles.flag}
          />
          <Typography type={TypographyType.BodySmall}>{review.country_name}</Typography>
        </div>
      </div>
    </div>
  );
};

export default UserReviewCard;
