import cx from 'classnames';
import React from 'react';

import styles from './downloadAppleStore.module.scss';
import images from './images';

interface DownloadAppStoreProps {
  className?: string;
  locale?: string;
}

const DownloadAppleStore = ({ className, locale = 'en' }: DownloadAppStoreProps) => {
  return (
    <div
      className={cx(styles.container, className)}
      style={{ backgroundImage: `url(${images[String(locale).toLowerCase()]})` }}
    ></div>
  );
};

export default DownloadAppleStore;
