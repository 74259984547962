import {
  StarFillIcon,
  Typography,
  TypographyType,
  useBreakpoint,
} from '@vivino/js-react-common-ui';
import { useI18N } from '@vivino/js-web-common';
import React from 'react';

import UserReviewCard from './UserReviewCard';
import { UserReview, review1, review2, review3 } from './reviews';
import styles from './userReviews.module.scss';

const APP_STORE_RATING = 4.8;
const APP_STORE_RATINGS_COUNT_IN_K = 548.87;

const PLAY_STORE_RATING = 4.5;
const PLAY_STORE_REVIEWS_COUNT_IN_K = 194.338;

const TRANSLATIONS_PATH = 'components.premium_page.user_reviews';

const TRANSLATIONS = {
  whyVivinoIsWorthAScan: `${TRANSLATIONS_PATH}.why_vivino_is_worth_a_scan`,
  ratingInAppStore: `${TRANSLATIONS_PATH}.rating_in_app_store`,
  ratingInGooglePlay: `${TRANSLATIONS_PATH}.rating_in_google_play`,
  xRatings: `${TRANSLATIONS_PATH}.x_ratings`,
  xReviews: `${TRANSLATIONS_PATH}.x_reviews`,
};

interface FigureProps {
  rating: string;
  reviews: string;
  isMobile: boolean;
}

const Figure = ({ rating, reviews, isMobile }: FigureProps) => {
  const ratingFontSize = isMobile ? TypographyType.LabelLarge : TypographyType.TitleLarge;
  const reviewsFontSize = isMobile ? TypographyType.BodyMedium : TypographyType.BodyLarge;

  return (
    <div className={styles.figure}>
      <StarFillIcon className={styles.star} />
      <div>
        <Typography type={ratingFontSize} component="span">
          {`${rating} `}
        </Typography>
        <Typography type={reviewsFontSize} component="span">
          {reviews}
        </Typography>
      </div>
    </div>
  );
};

interface UserReviewsProps {
  title?: React.ReactNode;
  userReviews?: UserReview[];
}

const userReviewsData = [review1, review2, review3];

const UserReviews = ({ title, userReviews = userReviewsData }: UserReviewsProps) => {
  const { isMobile } = useBreakpoint();
  const { t } = useI18N();

  const formatCount = (count: number) => `${Math.trunc(count)}K`;

  const figures = [
    {
      rating: t(TRANSLATIONS.ratingInAppStore, { rating: APP_STORE_RATING }),
      reviews: t(TRANSLATIONS.xRatings, { count: formatCount(APP_STORE_RATINGS_COUNT_IN_K) }),
    },
    {
      rating: t(TRANSLATIONS.ratingInGooglePlay, { rating: PLAY_STORE_RATING }),
      reviews: t(TRANSLATIONS.xReviews, { count: formatCount(PLAY_STORE_REVIEWS_COUNT_IN_K) }),
    },
  ];

  return (
    <div className={styles.userReviews}>
      {title ? (
        title
      ) : (
        <Typography
          type={isMobile ? TypographyType.HeadlineMedium : TypographyType.DisplaySmall}
          className={styles.title}
        >
          {t(TRANSLATIONS.whyVivinoIsWorthAScan)}
        </Typography>
      )}
      <div className={styles.figures}>
        {figures.map(({ rating, reviews }, i) => (
          <Figure
            rating={`${rating} `}
            reviews={reviews}
            isMobile={isMobile}
            key={`appFigure-${i}`}
          />
        ))}
      </div>
      <div className={styles.userReviewCards}>
        {userReviews.map((userReview, i) => (
          <UserReviewCard review={userReview} key={i} />
        ))}
      </div>
    </div>
  );
};

export default UserReviews;
