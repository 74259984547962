import cx from 'classnames';
import React from 'react';

import styles from './downloadGooglePlay.module.scss';
import images from './images';

interface DownloadGooglePlayProps {
  className?: string;
  locale?: string;
}

const DownloadGooglePlay = ({ className, locale = 'en' }: DownloadGooglePlayProps) => (
  <div
    className={cx(styles.container, className)}
    style={{ backgroundImage: `url(${images[String(locale).toLowerCase()]})` }}
  ></div>
);

export default DownloadGooglePlay;
