import { DefaultPageProps } from '@webtypes/common';
import React from 'react';
import Page from 'vivino-js/utilities/Page/Page';

import AppPage from '@components/AppPage/AppPage';

interface Props extends DefaultPageProps {
  statistics: any;
  appleStoreUrl: string;
  googlePlayUrl: string;
}

export default ({
  translations,
  appleStoreUrl,
  googlePlayUrl,
  statistics,
  locale,
  ...rest
}: Props) => {
  return (
    <Page translations={translations} {...rest}>
      <AppPage
        locale={locale}
        appleStoreUrl={appleStoreUrl}
        googlePlayUrl={googlePlayUrl}
        statistics={statistics}
      />
    </Page>
  );
};
