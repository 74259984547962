import { useBreakpoint } from '@context/DeviceBreakpointContext/DeviceBreakpointContext';
import {
  Accordion,
  Button,
  ButtonTheme,
  DeferredImage,
  Typography,
  TypographyType,
} from '@vivino/js-react-common-ui';
import { Grid, VivinoLogo, trackEvent, useI18N } from '@vivino/js-web-common';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import Layout from 'vivino-js/utilities/Layout/Layout';

import DownloadAppStore from '@components/icons/DownloadAppleStore/DownloadAppleStore';
import DownloadGooglePlay from '@components/icons/DownloadGooglePlay/DownloadGooglePlay';
import UserReviews from '@components/shared/LandingPages/UserReviews/UserReviews';

import styles from './appPage.module.scss';

const TRANSLATIONS = {
  tastingNotes: 'components.app_page.tasting_notes',
  scannedLabels: 'components.app_page.scanned_labels',
  joingOtherUsers: 'components.app_page.join_70_million',
  searchableWines: 'components.app_page.searchable_wines',
  downloadForFree: 'components.app_page.download_for_free',
  trackYourTastes: 'components.app_page.track_your_tastes',
  keepYourRecords: 'components.app_page.keep_your_records',
  scanYourWay: 'components.app_page.scan_your_way_to_better_wine',
  pairLikeASommelier: 'components.app_page.pair_like_a_sommelier',
  findPerfectPairings: 'components.app_page.find_perfect_pairings',
  understandAnyBottle: 'components.app_page.understand_any_bottle',
  connectWithCommunity: 'components.app_page.connect_with_comminuty',
  addFriendsAndFamily: 'components.app_page.add_friends_and_family',
  manageYourCellar: 'components.app_page.manage_your_cellar',
  buyWinesThatMatchYou: 'components.app_page.buy_wines_that_match_you',
  useOurCellarFeatures: 'components.app_page.use_our_cellar_features',
  rightBottle: 'components.app_page.never_pick_the_wrong_bottle_again',
  vivinoWineAppInNumbers: 'components.app_page.vivino_wine_app_in_numbers',
  usingCommunityDataToStock: 'components.app_page.using_community_data_to_stock',

  getTheApp: 'common.get_the_app',
  wineRatings: 'common.wine_ratings',
  wineReviews: 'common.wine_reviews',
  joinOtherScanners: 'common.join_over_70_million_vivino_users',
  worldsNumberOneApp: 'common.welcome_to_the_worlds_number_one_wine_app',
  frequesntlyAskedQuestions: 'common.frequently_asked_questions',
  trustedByMillionsOfWineLovers: 'common.trusted_by_millions_of_wine_lovers',
};

const accordionData = [
  {
    title: 'components.app_page.is_the_app_free',
    content: 'components.app_page.is_the_app_free_answer',
  },
  {
    title: 'components.app_page.how_do_ratings_work',
    content: 'components.app_page.how_do_ratings_work_answer',
  },
  {
    title: 'components.app_page.how_do_i_scan_a_label',
    content: 'components.app_page.how_do_i_scan_a_label_answer',
  },
  {
    title: 'components.app_page.can_i_track_my_wines',
    content: 'components.app_page.can_i_track_my_wines_answer',
  },
  {
    title: 'components.app_page.can_i_follow_other_members',
    content: 'components.app_page.can_i_follow_other_members_answer',
  },
  {
    title: 'components.app_page.what_makes_recommendations_personalized',
    content: 'components.app_page.what_makes_recommendations_personalized_answer',
  },
  {
    title: 'components.app_page.can_i_purchase_wine_directly',
    content: 'components.app_page.can_i_purchase_wine_directly_answer',
  },
  {
    title: 'components.app_page.can_vivino_help_with_wine_knowledge',
    content: 'components.app_page.can_vivino_help_with_wine_knowledge_answer',
  },
];

interface AppPageProps {
  appleStoreUrl: string;
  googlePlayUrl: string;
  statistics: any;
  locale: string;
}

const AppPage = ({ appleStoreUrl, googlePlayUrl, statistics, locale }: AppPageProps) => {
  const { t } = useI18N();
  const { isMobile } = useBreakpoint();
  const [downloadLink, setDownloadLink] = useState('');
  const userAgent = globalThis.navigator?.userAgent;
  const appleDevice = userAgent?.match(/iPhone|iPad|iPod|Macintosh/i);

  useEffect(() => {
    const link = appleDevice ? appleStoreUrl : googlePlayUrl;

    setDownloadLink(link);
  }, []);

  function trackDownload() {
    trackEvent({
      event: 'App Page - Download',
    });
  }

  function formatStatNumber(num) {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1) + 'b+';
    } else if (num >= 1000000) {
      return Math.floor(num / 1000000) + 'm+';
    }
    return num.toString();
  }

  return (
    <div className={styles.container}>
      <div className={cx(styles.stickyHeader)}>
        <Grid>
          <div className={styles.headerContent}>
            <a href="/" className={styles.logo} aria-label="vivino.com">
              <VivinoLogo />
            </a>
            <div className={styles.buttons}>
              <Button onClick={trackDownload} href={downloadLink} theme={ButtonTheme.Primary}>
                {t(TRANSLATIONS.getTheApp)}
              </Button>
            </div>
          </div>
        </Grid>
      </div>
      <section className={styles.heroSection}>
        <Layout marginSmall>
          <div className={styles.container}>
            <div className={styles.textContainer}>
              <Typography type={TypographyType.DisplaySmall} className={styles.title}>
                {t(TRANSLATIONS.worldsNumberOneApp)}
              </Typography>
              <Typography type={TypographyType.BodyExtraLarge}>
                {t(TRANSLATIONS.joinOtherScanners)}
              </Typography>
              <div className={styles.appIcons}>
                <a
                  onClick={trackDownload}
                  href={googlePlayUrl}
                  aria-label="android app"
                  className={cx(styles.android, { [styles.showAndroid]: isMobile && !appleDevice })}
                >
                  <DownloadGooglePlay locale={locale} className={styles.svg} />
                </a>
                <a
                  onClick={trackDownload}
                  href={appleStoreUrl}
                  aria-label="ios app"
                  className={cx(styles.ios, { [styles.showIOS]: isMobile && appleDevice })}
                >
                  <DownloadAppStore locale={locale} className={styles.svg} />
                </a>
              </div>
            </div>
            <div
              role="img"
              className={styles.image}
              style={{ backgroundImage: "url('/images/app/app_icon.svg')" }}
            ></div>
          </div>
        </Layout>
      </section>
      <Grid>
        <Layout marginSmall>
          <section className={styles.scanSection}>
            <div className={styles.ctaContainer}>
              <div className={styles.text}>
                <Typography
                  type={isMobile ? TypographyType.HeadlineSmall : TypographyType.HeadlineExtraLarge}
                >
                  {t(TRANSLATIONS.scanYourWay)}
                </Typography>
                <Typography type={TypographyType.BodyMedium}>
                  {t(TRANSLATIONS.rightBottle)}
                </Typography>
              </div>
              <div>
                <Button onClick={trackDownload} href={downloadLink}>
                  {' '}
                  {t(TRANSLATIONS.downloadForFree)}
                </Button>
              </div>
            </div>
            <DeferredImage src={'/images/app/scan.png'}>
              <div
                className={styles.image}
                role="img"
                style={{ backgroundImage: "url('/images/app/scan.png')" }}
              ></div>
            </DeferredImage>{' '}
          </section>
        </Layout>
        <Layout marginSmall>
          <section className={styles.pairingsSection}>
            <div className={styles.item}>
              <div>
                <DeferredImage src={`/images/app/${locale}/Beyond.png`}>
                  <div
                    className={styles.image}
                    role="img"
                    style={{ backgroundImage: `url('/images/app/${locale}/Beyond.png')` }}
                  ></div>
                </DeferredImage>
              </div>

              <div className={styles.text}>
                <Typography
                  type={isMobile ? TypographyType.HeadlineSmall : TypographyType.HeadlineMedium}
                >
                  {t(TRANSLATIONS.understandAnyBottle)}
                </Typography>
                <Typography type={TypographyType.BodyMedium}>
                  {t(TRANSLATIONS.tastingNotes)}
                </Typography>
              </div>
            </div>
            <div className={styles.item}>
              <div>
                <DeferredImage src={`/images/app/${locale}/Pairing.png`}>
                  <div
                    role="img"
                    className={styles.image}
                    style={{ backgroundImage: `url('/images/app/${locale}/Pairing.png')` }}
                  ></div>
                </DeferredImage>
              </div>
              <div className={styles.text}>
                <Typography
                  type={isMobile ? TypographyType.HeadlineSmall : TypographyType.HeadlineMedium}
                >
                  {t(TRANSLATIONS.findPerfectPairings)}
                </Typography>
                <Typography type={TypographyType.BodyMedium}>
                  {t(TRANSLATIONS.pairLikeASommelier)}
                </Typography>
              </div>
            </div>
          </section>
        </Layout>
        <Layout marginSmall>
          <section className={styles.trackingSection}>
            <div className={styles.ctaContainer}>
              <div className={styles.text}>
                <Typography
                  type={isMobile ? TypographyType.HeadlineSmall : TypographyType.HeadlineExtraLarge}
                >
                  {t(TRANSLATIONS.trackYourTastes)}
                </Typography>
                <Typography type={TypographyType.BodyMedium}>
                  {t(TRANSLATIONS.keepYourRecords)}
                </Typography>
              </div>
              <div>
                <Button onClick={trackDownload} href={downloadLink}>
                  {' '}
                  {t(TRANSLATIONS.downloadForFree)}
                </Button>
              </div>
            </div>
            <DeferredImage src={'/images/app/track.png'}>
              <div
                role="img"
                className={styles.image}
                style={{ backgroundImage: "url('/images/app/track.png')" }}
              ></div>
            </DeferredImage>
          </section>
        </Layout>

        <Layout marginSmall>
          <section className={styles.communitySection}>
            <div className={styles.item}>
              <div>
                <DeferredImage src={'/images/app/community.png'}>
                  <div
                    role="img"
                    className={styles.image}
                    style={{ backgroundImage: "url('/images/app/community.png')" }}
                  ></div>
                </DeferredImage>
              </div>

              <div className={styles.text}>
                <Typography
                  type={isMobile ? TypographyType.HeadlineSmall : TypographyType.HeadlineMedium}
                >
                  {t(TRANSLATIONS.connectWithCommunity)}
                </Typography>
                <Typography type={TypographyType.BodyMedium}>
                  {t(TRANSLATIONS.addFriendsAndFamily)}
                </Typography>
              </div>
            </div>
            <div className={styles.item}>
              <div>
                <DeferredImage src={'/images/app/cellar.png'}>
                  <div
                    role="img"
                    className={styles.image}
                    style={{ backgroundImage: "url('/images/app/cellar.png')" }}
                  ></div>
                </DeferredImage>
              </div>
              <div className={styles.text}>
                <Typography
                  type={isMobile ? TypographyType.HeadlineSmall : TypographyType.HeadlineMedium}
                >
                  {t(TRANSLATIONS.manageYourCellar)}
                </Typography>
                <Typography type={TypographyType.BodyMedium}>
                  {t(TRANSLATIONS.useOurCellarFeatures)}
                </Typography>
              </div>
            </div>
          </section>
        </Layout>
        <Layout marginSmall>
          <section className={styles.matchingSection}>
            <DeferredImage src={'/images/app/buyingBG.webp'}>
              <div
                role="img"
                className={styles.bgImage}
                style={{ backgroundImage: "url('/images/app/buyingBG.webp')" }}
              ></div>
            </DeferredImage>
            <div className={styles.ctaContainer}>
              <div className={styles.text}>
                <Typography
                  type={isMobile ? TypographyType.HeadlineSmall : TypographyType.HeadlineExtraLarge}
                >
                  {t(TRANSLATIONS.buyWinesThatMatchYou)}
                </Typography>
                <Typography type={TypographyType.BodyMedium}>
                  {t(TRANSLATIONS.usingCommunityDataToStock)}
                </Typography>
              </div>
              <div>
                <Button onClick={trackDownload} href={downloadLink}>
                  {' '}
                  {t(TRANSLATIONS.downloadForFree)}
                </Button>
              </div>
            </div>
            <DeferredImage src={'/images/app/buy.png'}>
              <div
                role="img"
                className={styles.image}
                style={{ backgroundImage: "url('/images/app/buy.png')" }}
              ></div>
            </DeferredImage>
          </section>
        </Layout>
      </Grid>
      <section className={styles.byTheNumbersSection}>
        <Layout marginSmall>
          <div className={styles.container}>
            <Typography
              type={isMobile ? TypographyType.HeadlineSmall : TypographyType.DisplaySmall}
            >
              {t(TRANSLATIONS.vivinoWineAppInNumbers)}
            </Typography>
            <div className={styles.numbers}>
              <div className={styles.item}>
                <DeferredImage src={'/images/app/glass_with_star.webp'}>
                  <div
                    role="img"
                    className={styles.image}
                    style={{ backgroundImage: "url('/images/app/glass_with_star.webp')" }}
                  ></div>
                </DeferredImage>
                <div>
                  <Typography type={TypographyType.HeadlineExtraLarge}>
                    {formatStatNumber(statistics?.ratings_count)}
                  </Typography>
                  <Typography type={TypographyType.BodyExtraLarge}>
                    {t(TRANSLATIONS.wineRatings)}
                  </Typography>
                </div>
              </div>
              <div className={styles.item}>
                <DeferredImage src={'/images/app/person_heart_glass.webp'}>
                  <div
                    role="img"
                    className={styles.image}
                    style={{ backgroundImage: "url('/images/app/person_heart_glass.webp')" }}
                  ></div>
                </DeferredImage>
                <div>
                  <Typography type={TypographyType.HeadlineExtraLarge}>
                    {formatStatNumber(statistics?.reviews_count)}
                  </Typography>
                  <Typography type={TypographyType.BodyExtraLarge}>
                    {t(TRANSLATIONS.wineReviews)}
                  </Typography>
                </div>
              </div>
              <div className={styles.item}>
                <DeferredImage src={'/images/app/bottles.webp'}>
                  <div
                    role="img"
                    className={styles.image}
                    style={{ backgroundImage: "url('/images/app/bottles.webp')" }}
                  ></div>
                </DeferredImage>
                <div>
                  <Typography type={TypographyType.HeadlineExtraLarge}>
                    {formatStatNumber(statistics?.vintages_count)}
                  </Typography>
                  <Typography type={TypographyType.BodyExtraLarge}>
                    {t(TRANSLATIONS.searchableWines)}
                  </Typography>
                </div>
              </div>
              <div className={styles.item}>
                <DeferredImage src={'/images/app/scan_bottle.webp'}>
                  <div
                    role="img"
                    className={styles.image}
                    style={{ backgroundImage: "url('/images/app/scan_bottle.webp')" }}
                  ></div>
                </DeferredImage>
                <div>
                  <Typography type={TypographyType.HeadlineExtraLarge}>
                    {formatStatNumber(statistics?.scanned_labels_count)}
                  </Typography>
                  <Typography type={TypographyType.BodyExtraLarge}>
                    {t(TRANSLATIONS.scannedLabels)}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </section>
      <Grid>
        <Layout>
          <section className={styles.reviewSection}>
            <UserReviews
              title={
                <Typography
                  type={isMobile ? TypographyType.HeadlineSmall : TypographyType.DisplaySmall}
                  className={styles.title}
                >
                  {t(TRANSLATIONS.trustedByMillionsOfWineLovers)}
                </Typography>
              }
            />
          </section>
        </Layout>
      </Grid>
      <div className={styles.millionsSection}>
        <div className={styles.bottles}>
          <DeferredImage src={'/images/app/bottles/4.png'}>
            <div
              role="img"
              className={cx(styles.image, styles.image4)}
              style={{ backgroundImage: "url('/images/app/bottles/4.png')" }}
            ></div>
          </DeferredImage>
          <DeferredImage src={'/images/app/bottles/5.png'}>
            <div
              role="img"
              className={cx(styles.image, styles.image5)}
              style={{ backgroundImage: "url('/images/app/bottles/5.png')" }}
            ></div>
          </DeferredImage>
          <DeferredImage src={'/images/app/bottles/3.png'}>
            <div
              role="img"
              className={cx(styles.image, styles.image3)}
              style={{ backgroundImage: "url('/images/app/bottles/3.png')" }}
            ></div>
          </DeferredImage>
          <DeferredImage src={'/images/app/bottles/2.png'}>
            <div
              role="img"
              className={cx(styles.image, styles.image2)}
              style={{ backgroundImage: "url('/images/app/bottles/2.png')" }}
            ></div>
          </DeferredImage>
          <DeferredImage src={'/images/app/bottles/1.png'}>
            <div
              role="img"
              className={cx(styles.image, styles.image1)}
              style={{ backgroundImage: "url('/images/app/bottles/1.png')" }}
            ></div>
          </DeferredImage>
        </div>
        <div className={styles.text}>
          <Layout marginSmall>
            <div className={styles.container}>
              <Typography
                type={isMobile ? TypographyType.HeadlineExtraLarge : TypographyType.DisplayLarge}
              >
                {t(TRANSLATIONS.joingOtherUsers)}
              </Typography>
              <div>
                <Button
                  onClick={trackDownload}
                  size="large"
                  href={downloadLink}
                  theme={ButtonTheme.Primary}
                >
                  {t(TRANSLATIONS.getTheApp)}
                </Button>
              </div>
            </div>
          </Layout>
        </div>
      </div>
      <Grid>
        <Layout marginSmall>
          <section className={styles.faqSection}>
            <Typography
              type={isMobile ? TypographyType.HeadlineSmall : TypographyType.DisplaySmall}
              className={styles.title}
            >
              {t(TRANSLATIONS.frequesntlyAskedQuestions)}
            </Typography>
            <div className={styles.accordion}>
              {accordionData.map((item, index) => (
                <Accordion
                  key={index}
                  summary={
                    <Typography type={TypographyType.LabelLarge}>{t(item.title)}</Typography>
                  }
                  details={
                    <Typography
                      type={TypographyType.BodyMedium}
                      dangerouslySetInnerHTML={{ __html: t(item.content) }}
                    ></Typography>
                  }
                />
              ))}
            </div>
          </section>
        </Layout>
      </Grid>
    </div>
  );
};

export default AppPage;
