import en from './images/en.svg';
import ja from './images/ja.svg';
import zh from './images/zh.svg';
import da from './images/da.svg';
import de from './images/de.svg';
import es from './images/es.svg';
import fr from './images/fr.svg';
import it from './images/it.svg';
import pt from './images/pt.svg';
import ru from './images/ru.svg';
import nl from './images/nl.svg';
import sv from './images/sv.svg';

export default {
  en,
  ja,
  zh,
  da,
  de,
  es,
  fr,
  it,
  pt,
  ru,
  nl,
  sv,
};
